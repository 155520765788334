import * as React from "react";
import axios from 'axios';
import { Cookies } from "react-cookie";

class NameBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {name:"", message:"", API:"/api/"};
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {    this.setState({name: event.target.value});  }

  handleSubmit(event) {
    if(this.state.name.length){
    axios.post(this.state.API+`tw-change-name/`, {"hash":this.props.hash, "name":this.state.name}).then((res)=>{this.setState({'message':res.data.message})})
    }else{
      this.setState({"message":"No change, nothing to update!"})
    }
    event.preventDefault(); 
  }

  render() {
    return (
      <>
      <form onSubmit={this.handleSubmit}>        <label> Add your name to see how you rank on the leaderboard:</label>
        <p><input type="text" placeholder={this.props.name} onChange={this.handleChange} /> </p>       
          <p>{this.state.message}</p>
        <input type="submit" value="Set My Name" />
      </form>
      </>
    );
  }
  }


  export {NameBox};
