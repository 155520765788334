import react, {useState, useEffect} from "react";
import { Routes, Route, Link} from "react-router-dom";
import {Nav} from '../components/Nav'
import axios from 'axios';

function About(props){


const[name, setName] = useState("")
const API = "/api/";
const {cookies} = props;

useEffect(()=>{
 axios.get(API+`tw-leaderboard/?hash=`+cookies.get('hash'))
  .then(res => {
    if(res.data.name){
      setName(res.data.name)
    }
  })
},[])



const styles = "p {font-size: 16px;}"
return (<>
<main style={{textAlign: "left", padding:"50px"}}>
	<style>
		{styles}	
	</style>
	<Nav name={name}/>
	<p>
	<h1> Complete Rules</h1>
	<ul>
	<li>Each round is 90 seconds.</li>
	<li>Valid words must:
	     <ul>
	        <li> Start and end with the required letters</li>
			<li> Be at least 3 letters long</li> 
	        <li> Not have been used in a previous round</li>
	        <li> Contain any required Twixtiles. NOTE: If a Twixtile is the same as a start or end letter, the first or last letter DOES NOT count. E.g., if the first letter is R and the last letter is E, and you get an E as a Twixtile, ROSE is invalid, but RECEDE is valid.</li></ul></li>
	<li> For your first round, you score one point per letter in your word. If time runs out, the game ends and you score zero.</li>
	<li> For every additional twixtile, you recieve an additional point per letter in your word. E.g., if you have 1 twixtile, each letter is worth 2 points. If you have 3 twixtiles, each letter is worth 4 points.</li>
	<li> For each round beyond the first, you are guaranteed a portion of your score from the previous round. If you successfully complete a round and choose to end at that point,
	you keep your full score. However, if you continue and then cannot come up with a valid word, you lose 50% of the score from the previous round (NOT 50% of your total score, but only the immediately preceding round), and the game is over.
	</li>
	<li>You may try any words you'd like until time runs out, but only valid words will be accepted.</li>
	</ul><br/> <br/>
	<h2> Whats the deal with the rating?</h2>
	<p> The fun of any game is the sense of competition. But it wouldn't be fair to compare the scores of someone who got a starting letter of A and ending letter of S with someone who was starting with Q and ending with X. So the rating system is a way of leveling it out. The ratings are based on a system developed by Arpad Elo, for rating chess players. Each day, though you aren't playing at the same time, you will "play" a game against another player who has the same letters. After you've played, the game determines the rating using the Elo system. The short version is that if you beat someone with a higher rating, your rating increases more than if you beat someone with a lower rating, and vice versa. The greater the difference between your initial rating and your opponent, the greater your ability to increase your rating, but conversely, losing to someone with a much higher rating has only a small effect on your rating. So over time, your rating is representative of your skill relative to every player in the game.</p> 
	<br/> 
	</p>
</main>
</>);
}

export {About};
