import react, {useState, useEffect} from "react";
import { Routes, Route, Link} from "react-router-dom";
import axios from 'axios';
import {Nav} from '../components/Nav'
import Table from 'react-bootstrap/Table';

function History(props){

const [records, setRecords] = useState([]);
const[name, setName] = useState("")
const API = "/api/";
const {cookies} = props;

useEffect(()=>{
  axios.get(API+`tw-history/?hash=`+cookies.get('hash'))
  .then(res => {
    if(res.data.records.length){
    setRecords(res.data.records)
    setName(res.data.name)
    }else{
      window.location.href="/"
    }
  })
},[])

return(<>
<main>
<Nav name={name}/>
  <h2>History</h2>
  <Table striped size="sm" responsive variant="dark" style={{"margin":"50px"}}>
  
  <thead class="thead-dark">
  <tr>
    <th>Date</th>
    <th>First</th>
    <th>Last</th>
    <th>Middles</th>
    <th>Your Words</th>
    <th>Score</th>
    <th>Opponents Words</th>
    <th>Opponents Score</th>
    <th>New Rating</th>
  </tr>
    </thead>
    <tbody>
    {records.map((object, i) => {
          let data = object['data']['fields']['data']
          let opponent = object['opponent']
          let date = object['date']
          return  <tr key={date} >
                      <td >{date.split(" ")[0]}</td>
                      <td >{data["first"]}</td>
                      <td>{data.last}</td>
                      <td>{data['middles'].join(", ")}</td>
                      <td>{data['used'].join(", ")}</td>
                      <td>{data['score']}</td>
                      <td>{opponent?opponent["fields"]["data"]["used"].join(", "):[].toString()}</td>
                      <td>{opponent?opponent["fields"]["data"]["score"]:0}</td>
                      <td>{object['data']["fields"]["elo"]}</td>
                  </tr>
          
    
        })}
    </tbody>
    </Table>
</main>
</>);
}

export {History};
