import react, { useEffect, useState } from "react";
import { Routes, Route, Link, Redirect } from "react-router-dom";
import {Rules} from './Rules';
import Button from 'react-bootstrap/Button';
import {NameBox} from '../components/NameBox'
import {Nav} from '../components/Nav'
import axios from 'axios';

function Home(props){
    const {cookies} = props;
    const API = "/api/";
    const expires = new Date()
    expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 365);
    const [done, setDone] = useState(false);
    const [noCookies, setNoCookies] = useState(false)
    const [score, setScore] = useState(0)
    const [elo, setElo] = useState(1500)
    const [newUser, setNewUser] = useState(false)
    const [challenge, setChallenge] = useState(false)
    const [challenger, setChallenger] = useState(false)
    const [possible, setPossible] = useState("")
    const [used, setUsed]=useState([])
    const [middles, setMiddles]=useState([])
    const [name, setName] = useState("")
    const [opponent, setOpponent] = useState({})
    const [hash, setHash] = useState(cookies.get('hash'))
    const [messageClass, setMessageClass] = useState('visible hide')
    
    const [hasResponse, responseRecieved] = useState(false)
    const getShare = () => { 
        let longest = used.reduce(
          function (a, b) {
              return a.length > b.length ? a : b;
          }
        );
        if(middles.length){
          let used_middles = middles.filter(middle => longest.substring(1, longest.length-1).indexOf(middle) != -1 )    
          let uppers = used_middles.map(middle => middle.toUpperCase());
          var middlestring = uppers.join(", ")
          if(used_middles.length >1){
            var others = middlestring.substring(0, middlestring.length - 3) +" and "
          }else{
            var others = ""
          }
          middlestring = others + middlestring.substring(middlestring.length - 1);
        }else{
            middlestring = false;
        }
        let border = "💭💭💭💭\n\n";
        let string1 = "⏳⏳⏳⏳\n\nYou've got 90 seconds!\n\nWhats the longest "//\n"
        let string2 = "word you can find that starts with "+longest.charAt(0).toUpperCase()
        let string3 = "ends with "+ longest.charAt(longest.length - 1).toUpperCase()
        if(middlestring){
            string2 = string2 + ", "//\n" 
            string3 = string3 + ", and contains "+middlestring+"?\n\n"
        }else{
            string2 = string2 +" and "//\n"
            string3 = string3+"?\n\n"
        }
        let string4 = "How about:  "+longest.toUpperCase()+"?\n\n"
        let string4a ="Today's score:  " + score+"    My Rating: "+elo+"   \n\n"
        let string5 = "twxtl.com        \n\n"
        let shareable = string1+string2+string3+string4+string4a+string5+border
        navigator.clipboard.writeText(shareable);
        setMessageClass("visible")
        setInterval(()=>{setMessageClass("visible hide")}, 3500);
  }
//init
useEffect(()=>{
    let ic = new URLSearchParams(window.location.search).get('ic') || false;
    let cm = !! + new URLSearchParams(window.location.search).get('cm') || false;
    let hash = new URLSearchParams(window.location.search).get('hash') || cookies.get('hash') || "";
    let data = {}
    if(ic){
      hash = "ic_" + hash
      data["ic"] = ic
    }
    data["hash"] = hash
    axios.post(API+`tw-init/`, data)
    .then(res => {
      cookies.set('hash', res.data.hash, {
        path: '/',
        expires,
        maxAge:60 * 60 * 24 * 365,
        sameSite:'strict'

      });
      if(res.data.name){
        setName(res.data.name)
      }else{
        setNewUser(true)
      }
      //setName(res.data.name)
      setHash(res.data.hash)
    if([0,5].includes(res.data.status)){

        setScore(res.data.data.score)
        setElo(res.data.elo)
        setPossible(res.data.data.possible)
        setUsed(res.data.data.used)
        setMiddles(res.data.data.middles)
        setDone(true)
        setOpponent(res.data.opponent)
        if(res.data.status == 5 || !!+cm ){
          setChallenge(true)
        }
        if(cm){
          setChallenger(true);
        }
        cookies.set('hash', res.data.root, {
          path: '/',
          expires,
          maxAge:60 * 60 * 24 * 365,
          sameSite:'strict'
  
      });
      }else if(res.data.status != 3){
        window.location = "/game";
      }
      responseRecieved(true);
    })
},[]);
if(noCookies){
  return (<>
  <main>
    You must have cookies enabled to use this site.
    </main>
  </>)
}else if(hasResponse && !done){
let ic = new URLSearchParams(window.location.search).get('ic') || false;
return(
       <>
       < main>
	<h1 style={{fontFamily:"font-family: 'Rubik Glitch', cursive !important;"}}> Twixtiles!</h1>
  <Nav name={name} />
        <Rules />
        {!ic && <><Button style={{"backgroundColor":"#f5a020", "color":"black", "border":"none"}} onClick={()=>{window.location='/game?cm=1'}}>Challenge a Friend!</Button></>}<br/><br/>
        <Button style={{"backgroundColor":"black","color":"white", "border":"none"}} onClick={()=>{window.location='/about'}}>Complete Rules</Button>
       </main>
         </>
    );
}else if (!done){
  return(
    <>
    < main>
<h2>Loading...</h2>
    </main>
      </>
  )
}else{
    return (
        <>
        <main>
        <Nav name={name} />
          <h1>Game Over!</h1>
          {possible && <><h3>Look like you ran out of time!</h3><h3>You could have played: {possible}</h3></>}
          {score == 150000 && <><h1>YOU'VE BEATEN THE GAME TODAY!</h1>
          <h3>You played so hard that there were literally no more possible words!</h3>
          <h3>Your rating has increased by 50 points. Outstanding!</h3></>}
           <h2> Your score: {score}</h2>
           {!challenge && <><h2>Your current rating: {elo}</h2></>}
          <Button variant='primary'  onClick={getShare}>Share it!</Button><br />
          <p className={messageClass}>Copied to clipboard!</p>


          { !challenge &&
          <>{opponent.elo && !challenge ? <h4>You played {opponent.name?opponent.name:"an anonymous player"} today. They scored {opponent.score}, and their longest word was "{opponent.longWord}". {opponent.score >= score ? <>&nbsp;Couldn't beat them this time!</> : <>&nbsp;Nice job on your win!</> } </h4> :
          <h4> You set the bar! Come back in a bit to see how your opponent has fared.</h4>}
          <h2>Come back tomorrow for another shot!</h2></>}
          

          { challenge && <>
              <h4>Challenge Complete!</h4>
              {!challenger ?
                  <><h4>You played against {opponent.name}. They scored {opponent.score} and their longest word was "{opponent.longWord}".&nbsp; 
                    {opponent.score >= score ? <>&nbsp;Couldn't beat them this time!</> : <> &nbsp;Nice job on your win!</> }
                  </h4></>
                  :
                  <><h4>Send this link to a friend! <br /><br />https://twxtl.com?ic={hash}</h4></>}
            </>}
  
          <Button variant="outline-light" style={{"backgroundColor":"#f5a020","border":"none"}} onClick={()=>{window.location='/game/?cm=1'}}>Challenge another friend!</Button> <br/> <br/>
          <Button variant="outline-light" style={{"backgroundColor":"#f5a020", "border":"none"}} onClick={()=>{window.location='leaderboard'}}>Leaderboard</Button>
        </main>
        </>
    );
}
}
export {Home};
