import react, {useState, useEffect} from "react";
import { Routes, Route, Link} from "react-router-dom";
import axios from 'axios';
import {NameBox} from '../components/NameBox';
import {Nav} from '../components/Nav'

function Profile(props){

const[name, setName] = useState("")
const API = "/api/";
const {cookies} = props;


if (!cookies.get('hash')){
	window.location = "/";
}

useEffect(()=>{
 axios.get(API+`tw-leaderboard/?hash=`+cookies.get('hash'))
  .then(res => {
    if(res.data.name){
      setName(res.data.name)
    }
  })
},[])

return (<>
<main><p>
<Nav name={name}/></p>
  <h2>Account Profile</h2>
  {cookies.get("hash") && <NameBox name={name} hash={cookies.get("hash")}/>}
   <br />
	<p> Note: Accurate leaderboard rankings depend on your account profile being shared across the devices you play on. If you already have an account profile on a different device, go to the profile page on *that device*, and use the link found there.</p>
    <br />
	<p> Share this one-time link with your other devices to sync your profile</p>
	<h3>twxtl.com?hash={cookies.get('hash')}</h3>
	</main>
</>);
}

export {Profile};
