import react from "react";
import { Routes, Route, Link, Navigate} from "react-router-dom";
import axios from 'axios';
import { Cookies } from "react-cookie";
import {WordBox} from '../components/WordBox';
import Button from 'react-bootstrap/Button';



class Game extends react.Component {
  constructor(props) {
    super(props);
    this.state = {
      "counter":90,
      "gameOver": false,
      "API":"/api/",
      "challenge_mode": !! + new URLSearchParams(window.location.search).get('cm') || 0,
      "option":false,
      "error":"",
      "classes":"visible hide",
      "used":[]
    }
   }

   endRound = (submission, force)=>{
     let data = {"hash":this.props.cookies.cookies.hash, 'submission':submission.toLowerCase().trim()}
     if(force){
       data['force'] = 1;
     }
     axios.post(this.state.API+`tw-round-ends/`, data)
    .then(res => {
      this.setState({"used":res.data.data.used})
      if(res.data.status == 0 || res.data.status == 5  || force){
        //clearInterval(this.state.interval)
        //this.setState({"done":true})
        window.location = "/"+(this.state.challenge_mode?"?cm=1":"");
      }else if(res.data.status == 1){
        this.setState({"classes":"visible"})
        setInterval(()=>{this.setState({"classes":"visible hide"})}, 3500);
      } else {
        clearInterval(this.state.interval)
        this.setState({"option":true, "gameData":res.data.data,})
      }
    })
   }

   continue = () =>{
    axios.post(this.state.API+`tw-user-continues/`, {"hash":this.props.cookies.cookies.hash})
    .then(res => {
        if(res.data.status == 4){
          window.location = "/"+(this.state.challenge_mode?"?cm=1":"");
        }else{
        let newState = {"gameData":res.data.data, 'counter':this.getCount(res), "option":false}
        this.setState(newState);
        this.countdown()
        }
      }
   )
  }

   endGame =() =>{
    axios.post(this.state.API+`tw-user-ends/`, {"hash":this.props.cookies.cookies.hash})
    .then(
      () => {window.location = "/"+(this.state.challenge_mode?"?cm=1":"")}
   )
   }
   
   getCount = (res) =>{
    let now = res.data['servertime'];
    let roundstart = res.data.data['roundstart'] ;
    let count = 90
    if (((now - roundstart) > 2) && ((now - roundstart) < 100)){
       count = Math.abs(93 - Math.round( (now-roundstart)));
    }
    return count;
   }

   sendBeacon = () =>{ navigator.sendBeacon('/api/tw-browser-closed/?hash='+this.props.cookies.cookies.hash);};
   visibilityPreprocess = ()=>{
    if (document.visibilityState === 'hidden') {
        this.sendBeacon();
    }}


   componentDidMount(){
    const expires = new Date()
    expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 365);
    if(!this.props.cookies.cookies.hash){
	   window.location = "/"+(this.state.challenge_mode?"?cm=1":"");
    }
    if ('onpagehide' in document) {
      document.addEventListener('pagehide', this.sendBeacon, { capture: true} );
    } else {
        document.addEventListener('unload', this.sendBeacon, { capture: true} );
        document.addEventListener('beforeunload', this.sendBeacon, { capture: true} );
    }
    document.addEventListener('visibilitychange', this.visibilityPreprocess, { capture: true} );
    let hash = this.props.cookies.cookies.hash
    if(this.state.challenge_mode && !hash.includes("challenge")){
      hash = "cm_"+ hash
    }
    axios.post(this.state.API+`tw-start-game/`, {"hash":hash})
    .then(res => {
      this.props.cookies.set('hash', res.data.hash, {
        path: '/',
        expires,
        maxAge:60 * 60 * 24 * 365,
        sameSite:'strict'

      });
      if (Object.keys(res.data.data).length === 0){
        window.location = "/"+(this.state.challenge_mode?"?cm=1":"")
      }
      if(res.data.status == 1){
        let newState = {"gameData":res.data.data, 'counter':this.getCount(res)}
        this.setState(newState, this.countdown);
      }
      if(res.data.status == 0 || res.data.status == 5){
        //this.setState({"done":true})
        window.location = "/"+(this.state.challenge_mode?"?cm=1":"")
      }
      if(res.data.status == 2){
        this.setState({gameData: res.data.data, 'option':true})
      }
    })
   }

   extractMiddles = () =>{
     let middles = this.state.gameData.middles
     let middlesString =""
     middles.forEach(element => {
       middlesString += element.toUpperCase()+" ";
     } 
     );
     return middlesString;
   }

   countdown = () =>{
      let interval = setInterval(()=>{
      if(this.state.counter > 1) {
         let counter = this.state.counter
         this.setState({"counter": counter-1})
      }else{
        this.endRound("", true)
      }
    }, 1000)
    this.setState({'interval':interval})
   }

   render(){
    //if(this.state.done){
    //  return(
    //  <Navigate to="/" />
    //  )
    //}else 
    if(!this.state.gameData) { 
    return(<>
        <main>
        <h2>Loading...</h2>
        </main>
        </>
        )
    }
    else if(!this.state.option){
      return (
        <>
          <main>
            <h2></h2>
            {this.state.gameData.middles.length == 0 ?
            <h3>Come up with a word that starts with {this.state.gameData.first.toUpperCase()}  and ends with {this.state.gameData.last.toUpperCase()} </h3>
            : <h3>Come up with a word that starts with {this.state.gameData.first.toUpperCase()}  and ends with {this.state.gameData.last.toUpperCase()} AND contains {this.extractMiddles()} </h3>
            }<p><WordBox endRound={this.endRound} classes={this.state.classes} /></p>
            <ul class="nav nav-pills">
              <li class="nav-item dropown">
              <Button variant='outline-light' style={{marginLeft:"20px", backgroundColor:"#b9c3c4", border:"none"}} class="nav-link dropdown-toggle btn-secondary" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Used Words</Button>
              <ul class="dropdown-menu">
                
                {this.state.used.map(function(word, index){
                    return <li><span class="dropdown-item">{word}---{word.length * (index+1)}</span></li>
                  })}


              </ul>
              </li>
            </ul>
          </main>
          <nav>
          <div>Countdown: {this.state.counter}</div>
          </nav>
        </>
      );
      }else{
          return (
            <>
            <main>
              
              <h1> Your word was VALID!</h1>
              <h2>Round complete!</h2>
              <h2> Your current score: {this.state.gameData.score}</h2>
              <h2>Continue?</h2>
              <div className="d-grid gap-2">
              <Button variant='success' class=".btn-block" onClick={this.continue}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
  <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
</svg>Yes</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div><br/>
              <Button variant='outline-danger' onClick={this.endGame}><span><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="red" class="bi bi-x-octagon-fill" viewBox="0 0 16 16">
  <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146zm-6.106 4.5L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z"/>
</svg></span>
&nbsp;&nbsp;No, Stop Now</Button>
            </main>
            </>
          );
      }
  }
}


export {Game};
