import react, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';



function Nav(props){
    return(
        <>
        <ul class="nav nav-pills">
<li class="nav-item dropown">
<Button variant='outline-light' size='lg' style={{marginLeft:"20px", backgroundColor:"#b9c3c4", border:"none"}} class="nav-link dropdown-toggle btn-secondary" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Menu</Button>
<ul class="dropdown-menu">
  <li><a class="dropdown-item" href="/leaderboard">Leaderboard</a></li>
  <li><a class="dropdown-item" href="/about">Rules</a></li>
  <li><a class="dropdown-item" href="/about">About</a></li>
  <li><a class="dropdown-item" href="/profile">User Profile</a></li>
  <li><a class="dropdown-item" href="/history">Daily History</a></li>
  <li><a class="dropdown-item" href="/challenges">Challenge History</a></li>
  <li><hr class="dropdown-divider" /></li>
  <li><a class="dropdown-item" href="/">Home</a></li>
</ul>
</li>
</ul>
   <p style={{textAlign:"left",fontSize:".8em", marginLeft:"20px", marginBottom:"30px"}}>{props.name ? "You're playing as "+ props.name :"Your username has not been set."}<br />
   <div style={{fontSize:".7em", marginTop:"0px"}}>Change your username and sync devices <a href="/profile">here</a></div></p>
	    
</>
)

}

export {Nav};
