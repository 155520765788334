import react from "react";
import { Routes, Route, Link} from "react-router-dom";
import Button from 'react-bootstrap/Button';

function Rules(){
//initGame

const incoming_challenge = new URLSearchParams(window.location.search).get('ic') || false;

return (<>
<main>
  <p>You have 90 seconds to think of a word that starts and ends with two randomly chosen letters.<br/><br />
  In the 1st round, each letter in your word is worth 1 point. If you play a 2nd round, your word now must contain an additional letter (a Twixtile). All the letters in your word are now worth 2 points.<br />
  Each additional round adds an additional Twixtile, and increases the value of your letters by an additional point. Your score is cumulative across all rounds.</p>
  <p>Be careful! Once you've used a word, you can't use it again, and if you can't come up with a word before time expires, you lose a percentage of your points, and the game ends</p>
</main>
<nav>
<Button style={{"backgroundColor":"#f5a020", "color":"black", "border":"none"}} onClick={()=>{window.location='/game'}}>{incoming_challenge?<>Accept Challenge!</>:<>Let's Play!</>}</Button>
<br/><br/>
</nav>
<br />
</>);
}

export {Rules};