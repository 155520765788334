import './App.css';
import { Routes, Route, Link } from "react-router-dom";
import {Game} from './pages/Game'
import {Home} from './pages/Home'
import { withCookies } from 'react-cookie';
import { Leaderboard } from './pages/Leaderboard';
import { History } from './pages/History';
import { Challenges } from './pages/Challenges';
import { About } from './pages/About';
import { Profile } from './pages/Profile';
import CacheBuster from './CacheBuster';

function App(props) {
  return (
    
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }
          return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home cookies={props.cookies}/>} />
        <Route path="game/" element={<Game cookies={props.cookies} />} />
        <Route path="leaderboard/" element={<Leaderboard cookies={props.cookies} />} />
        <Route path="history/" element={<History cookies={props.cookies} />} />
        <Route path="challenges/" element={<Challenges cookies={props.cookies} />} />
        <Route path="about/" element={<About cookies={props.cookies} />} />
	<Route path="profile/" element={<Profile cookies={props.cookies} />} />
      </Routes>
    </div>
    );
  }}
  </CacheBuster>
  );
}

export default withCookies(App);
